<template>
    <div class="application-input-theme-transaction">
        <AppTable v-if="transactionsLoading" key="a" :head="transactionsHead" :data="skeletonBank" theme="card-disabled">
            <template #cell.paidAt><div class="placeholder placeholder--macro"></div></template>
            <template #cell.paymentType><div class="placeholder placeholder--small"></div></template>
            <template #cell.senderCard><div class="placeholder placeholder--small"></div></template>
            <template #cell.status><div class="placeholder placeholder--small"></div></template>
            <template #cell.commissionAmount><div class="placeholder placeholder--small"></div></template>
            <template #cell.receiverCard><div class="placeholder placeholder--small"></div></template>
            <template #cell.totalAmount><div class="placeholder placeholder--small"></div></template>
            <template #cell.account><div class="placeholder placeholder--large"></div></template>
            <template #cell.date><div class="placeholder placeholder--small"></div></template>
            <template #cell.action><div class="placeholder placeholder--button"></div></template>
        </AppTable>
        <div v-else-if="!transactionsData.length" class="empty-transactions-table">
            <div class="empty-transactions-table__header">
                <span v-for="item in transactionsHead[0]">{{ $t(item.name) }}</span>
            </div>
            <div class="empty-transactions-table__body">
                <div class="empty-transactions-table__description">
                    {{ $t("components.common.noDataFoundForTheseDatesInThePeriodTryChangingThePeriodDates") }}<br>
                    <span>{{ $t("components.common.periodSizeNoMoreThanSixtyDays") }}</span>
                </div>
                <AppButton class="empty-transactions-table__button" theme="danger" size="medium-b" @click="$emit('reset')">{{ $t("components.common.resetFilter") }}</AppButton>
            </div>
        </div>
        <AppTable v-else :head="transactionsHead" :data="transactionsData" :additional-rows="additionalRows" theme="card">
            <template #cell.paidAt="{item}">{{ formatDate(item.paidAt) }}</template>
            <template #cell.sender="{item}">
                <div v-if="item.senderCard" class="table-large-text">
                    {{ item.senderCard.cardHolder }}<br>{{ item.senderCard.cardNumber | formatCardNumber }}<br> ({{ item.senderCard.bankName }})
                </div>
                <div v-else>-</div>
            </template>
            <template #cell.receiverCard="{item}">
                <div v-if="item.receiverCard" class="table-large-text">
                    {{ item.receiverCard.cardHolder }}<br>{{ item.receiverCard.cardNumber | formatCardNumber }}<br> ({{ item.receiverCard.bankName }})
                </div>
                <div v-else-if="item.merchant">{{ item.merchant.merchantName }}</div>
                <div v-else>-</div>
            </template>
            <template #cell.status="{item}">
                <div class="default-tag clickable-item" :class="{'default-tag--theme-danger': !item.status}" v-tooltip="item.status ? $t('components.common.transactionCompletedSuccessfully') : item.message">
                    {{ item.statusInfo.name }}
                </div>
            </template>
            <template #cell.commissionAmount="{item}">
                {{ formatNumber(item.commissionAmount / 100, true) }}
            </template>
            <template #cell.totalAmount="{item}">
                {{ formatNumber(item.totalAmount / 100, true) }} {{ item.currency }}
            </template>

            <template #cell.paymentType="{item}">
                <div class="application-input-title">
                    {{ $t("components.common.transactionType") }}
                </div>
                <div class="default-tag default-tag--theme-primary clickable-item" v-tooltip="item.paymentType.paymentTypeDescription" @click="$emit('filter', 'paymentType', item.paymentType.paymentType)">
                    {{ item.paymentType.paymentTypeDescription.slice(0, 12) + (item.paymentType.paymentTypeDescription.length < 12 ? '' : '...') }}
                </div>
            </template>
            <template #cell.account="{item}">
                <div class="application-input-title">
                    {{ $t("components.common.account") }}
                </div>
                <div class="application-input-description table-large-text">{{ item.account }}</div>
            </template>

            <template #cell.message="{item}">
                <div class="application-input-title">
                    {{ $t("components.common.aComment") }}
                </div>
                <div class="application-input-description table-large-text">{{ item.message }}</div>
            </template>

            <template #cell.action="{item}">
                <div class="transaction-mobile-action">
                    <LoadingContent :loading="sendSmsLoading">
                        <AppButton size="medium-b" :theme="item.transactionId in sentSms ? 'blueish-b' : 'primary-outline'" @click="sendTransactionSms(item.transactionId)">
                        <span v-if="item.transactionId in sentSms">
                            {{ $t("components.common.waitWithoutPoints") }} ({{ sentSms[item.transactionId] | timeFromMS }})
                        </span>
                            <span v-else>
                           {{ $t("components.common.sendACheck") }}
                        </span>
                        </AppButton>
                    </LoadingContent>
                </div>
            </template>
        </AppTable>
    </div>
</template>
<script>
import AppTable from "../../AppTable";
import LoadingContent from "../../LoadingContent";
import AppButton from "../../AppButton";
import {get, post} from "../../../../helpers/api";
import {EventBus} from "../../../../helpers/eventBus";
import ListUtils from "../../../../mixins/ListUtils";
import filterDate from "../../../../mixins/FilterDate";
import {formatCardNumber} from "../../../../helpers/numbers";
import ChatMessagesService from "../../../../services/chat/ChatMessagesService";
export default {
    name: 'TransactionsMobileTable',
    components: {AppButton, LoadingContent, AppTable},
    mixins: [ListUtils, filterDate],
    filters: {
        formatCardNumber
    },
    props: {
        commonData: Object,
        isChat: Boolean,
        filter: Object,
    },
    data() {
        return {
            transactionsLoading: false,
            transactionsData: [],
            transactionsHead: [[
                {
                    name: 'components.common.transactionID',
                    key: 'transactionId'
                },
                {
                    name: 'components.common.dateAndTime',
                    key: 'paidAt'
                },
                {
                    name: 'components.common.sender',
                    key: 'sender'
                },
                {
                    name: 'components.common.recipient',
                    key: 'receiverCard'
                },
                {
                    name: 'components.common.amount',
                    key: 'totalAmount'
                },
                {
                    name: 'components.common.commission',
                    key: 'commissionAmount'
                },
                {
                    name: 'common.status',
                    key: 'status'
                },
                {
                    name: 'common.actions',
                    key: 'action',
                    size: 'short'
                },
            ]],
            skeletonBank: new Array(10).fill(0),
            sendSmsLoading: false,
            sentSms: {},
            ticker: null,
                additionalRows: [
                [
                    {
                        key: 'account',
                        colspan: '2'
                    },
                    {
                        name: 'Тип операции',
                        key: 'paymentType',
                    },
                    {
                        key: 'message',
                        colspan: '5'
                    },
                ]
            ]
        }
    },
    mounted() {
        this.loadTransactionData();
    },
    beforeDestroy() {
        if(this.ticker) {
            clearInterval(this.ticker);
        }
    },
    watch: {
        filter: {
            handler: 'loadTransactionData',
            deep: true
        }
    },
    methods: {
        loadTransactionData() {
            this.transactionsLoading = true;
            this.transactionsData = [];

            const params = new URLSearchParams();
            Object.entries(this.filter).forEach(([key ,value]) => {
                if(value) {
                    params.append(key, value)
                }
            })

            get(`/asaka-mobile/card-transaction?` + params.toString())
                .then((res) => {
                    if(res.data.success) {
                        this.transactionsData = res.data.data;
                    }
                })
                .finally(() => {
                    setTimeout(() => {
                        this.transactionsLoading = false
                    }, 400);
                })
        },
        updateDelays() {
            const obj = {};
            for(let key in this.sentSms) {
                const d = this.sentSms[key] - 1000;
                if(d > 0) {
                    obj[key] = d;
                }
            }
            this.sentSms = obj;
            if(Object.keys(obj).length === 0) {
                clearInterval(this.ticker);
                this.ticker = null;
            }
        },
        sendTransactionSms(transaction) {
            if(!this.commonData.phone) {
                return this.$store.commit('notifications/error', 'Для отправки СМС выберите номер в карточке клиента');
            }
            if(this.sendSmsLoading || this.sentSms[transaction]) return;
            this.sendSmsLoading = true;
            const chatData = this.$store.state.chat.chatsData[this.$store.state.chat.activeChatId];
            post('/send-sms', {
                phone: this.commonData.phone,
                transaction,
                lang: this.commonData.lang,
                is_digital: this.isChat
            })
                .then(({data}) => {
                    this.sentSms[transaction] = 60000;
                    if(this.isChat) {
                        ChatMessagesService.inputChatMessage(data.links.join(' '), chatData);
                        this.$emit('close');
                    } else {
                        if(!this.ticker) {
                            this.ticker = setInterval(() => this.updateDelays(), 1000);
                        }
                    }
                })
                .catch(e => {
                    this.$store.commit('notifications/error', 'Не удалось отправить СМС')
                })
                .finally(() => {
                    this.sendSmsLoading = false;
                })
        }
    }
}
</script>
<style lang="scss">
.empty-transactions-table {
    background: #FFFFFF;
    &__header {
        padding: 14px 84px 15px 36px;
        display: flex;
        justify-content: space-between;
        background: #EDF2FF;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
    }
    &__body {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 100px 0;
    }
    &__description {
        margin-bottom: 16px;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.005em;
        span {
            color: var(--color-gray-lighten);
        }
    }
    &__button {
        padding-left: 18px;
        padding-right: 18px;
    }
}
.transaction-mobile-action {
    display: flex;
    justify-content: flex-end;
    margin-right: -20px;
    white-space: nowrap;
}
</style>
