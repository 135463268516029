<template>
    <div class="transactions-mobile-page">
        <div class="product-info-heading mb-6-a">{{ $t("components.common.transactions") }}</div>
        <div class="d-flex justify-content-between mb-4-a">
            <div class="transactions-mobile-page__nav nav-list" :class="{'nav-list--scrolled-left': scrolledLeft, 'nav-list-scrolled-right': scrolledRight}">
                <div class="nav-list__scroller" ref="scroller" @scroll="scrolled = $event.target.scrollLeft">
                    <div v-for="item in paymentTabs" :key="item.value" class="nav-list__item" :class="{'nav-list__item--active': item.value === filter.paymentType}" @click="filter.paymentType = item.value">
                        {{ item.name }}
                    </div>
                </div>
                <div v-if="false" class="nav-list__target nav-list__target--left" @mouseleave="stopScroll" @mouseenter="scrollLeft"></div>
                <div v-if="false" class="nav-list__target nav-list__target--right" @mouseleave="stopScroll" @mouseenter="scrollRight"></div>
            </div>
            <AppButton class="pl-8-a pr-8-a" @click="openFilterModal">
                <AppIcon class="mr-10" icon="filter"/>
                {{ $t("components.common.filter") }}
            </AppButton>
        </div>
        <ApplicationFilterPills ref="pills" :filter-sources="filterSources" :default-filter="defaultFilter" :filter="filter" :excluded-filters="excludedFilters"
                                :filter-parser="parseFilters" :parse-options="filterPillsOptions"
                                @input="onFilterInput" @clear="onFilterReset"/>
        <TransactionsMobileTable class="transactions-mobile-page__table" :filter="workingFilter" :is-chat="isChat" :common-data="commonData" @filter="onFilterInput" @reset="onFilterReset"/>
        <ApplicationModal v-if="filterOpened" :render-inputs="renderInputs" :model="tempFilter" :error-messages="errorMessages" name="components.common.filter" icon="filter"
                          @input="onTempFilterInput" @submit="onTempFilterSubmit" @reset="onTempFilterReset" @close="onTempFilterClose"/>
    </div>
</template>
<script>
import TransactionsMobileTable from "./TransactionsMobileTable";
import NavTabs from "../../NavTabs";
import AppIcon from "../../AppIcon";
import AppButton from "../../AppButton";
import ApplicationFilterPills from "../../../admin/ApplicationFilterPills";
import ApplicationModal from "../../../modals/ApplicationModal";

const mapDictionary = (object) => {
    return Object.entries(object).map(([value, name]) => ({value, name}));
}

export default {
    name: 'TransactionsMobilePage',
    components: {ApplicationModal, ApplicationFilterPills, AppButton, AppIcon, NavTabs, TransactionsMobileTable},
    props: {
        isChat: Boolean,
        commonData: Object,
        userId: Number,
        cardsList: Array
    },
    data() {
        const transactionFilter = {
            dateFrom: '',
            dateTo: '',
            card: '',
            paymentType: '',
            status: '',
            transaction: ''
        }
        return {
            defaultFilter: {
                ...transactionFilter
            },
            filter: {
                ...transactionFilter
            },
            tempFilter: {
                ...transactionFilter
            },
            errorMessages: {
                date: []
            },
            filterOpened: false,
            excludedFilters: [
                'dateFrom', 'dateTo', 'paymentType', 'card', 'status'
            ],
            filterPillsOptions: {
                dateFrom: 'dateFrom',
                dateTo: 'dateTo'
            },
            scrolled: 0,
            scrollWidth: 0,
            // todo: Доделать scrolledLeft, scrolledRight
            scrolledLeft: false,
            scrolledRight: false,
            scrollTimeout: null,
            filterSources: {}
        }
    },
    computed: {
        workingFilter() {
            const { card } = this.filter;
            const exclude = ['card'];

            const o = {...this.filter};
            exclude.forEach(e => delete o[e]);

            return {
                ...o,
                id: card ? card : this.userId,
                where: card ? 'card' : 'user',
            }
        },
        renderInputs() {
            return [
                {
                    type: 'input',
                    name: 'components.common.transactionID',
                    key: 'transaction',
                    removable: true
                },
                {
                    type: 'select',
                    name: 'components.common.transactionStatus',
                    key: 'status',
                    items: this.transactionStatuses,
                    removable: true
                },
                {
                    type: 'hidden',
                    name: '',
                    key: 'dateFrom',
                },
                {
                    type: 'hidden',
                    name: '',
                    key: 'dateTo',
                },
                {
                    type: 'double-date',
                    name: 'components.common.periodShort',
                    key: 'date',
                    keys: ['dateFrom', 'dateTo'],
                    removable: true
                },
                {
                    type: 'select',
                    name: 'components.common.transactionCardPay',
                    key: 'card',
                    items: this.cardsParsed,
                    removable: true
                },
            ]
        },
        cardsParsed() {
            if(!this.cardsList) return [];
            return this.cardsList.map(card => ({
                name: card.cardNumberMasked,
                value: card.cardId
            }))
        },
        paymentFetched() {
            return this.$store.state.asakaMobilePaymentTypesFetched;
        },
        transactionStatuses() {
            return mapDictionary(this.$store.state.asakaMobileTransactionStatuses);
        },
        typesLoading() {
            return this.$store.state.asakaMobilePaymentTypesLoading;
        },
        paymentTypes() {
            return mapDictionary(this.$store.state.asakaMobilePaymentTypes);
        },
        paymentTabs() {
            const o = [...this.paymentTypes];
            o.unshift({value: '', name: 'Все'});
            return o;
        }
    },
    mounted() {
        if(!this.paymentFetched && !this.typesLoading) {
            this.$store.dispatch('fetchAsakaMobilePaymentTypes');
        }
    },
    methods: {
        openFilterModal() {
            this.tempFilter = { ...this.filter };
            this.filterOpened = true;
        },
        onFilterInput(key, value) {
            this.filter[key] = value;
        },
        onTempFilterInput(key, value) {
            if(key === 'dateFrom' || key === 'dateTo') {
                this.errorMessages.date = [];
            }
            this.tempFilter[key] = value;
        },
        onTempFilterClose() {
            this.errorMessages.date = [];
            this.filterOpened = false;
        },
        onTempFilterSubmit() {
            const dF = !!this.tempFilter.dateFrom;
            const dT = !!this.tempFilter.dateTo;
            if(Math.abs(dF - dT) === 1) {
                this.errorMessages.date = ['Обязательно заполните оба поля'];
            } else {
                this.errorMessages.date = [];
                this.filter = { ...this.tempFilter };
                this.filterOpened = false;
            }

        },
        onTempFilterReset() {
            this.errorMessages.date = [];
            this.tempFilter = { ...this.defaultFilter };
        },
        onFilterReset() {
            this.filter = { ...this.defaultFilter };
        },
        parseFilters(filters, model) {
            let arr = [];
            if(model.card) {
                arr.push({
                    name: this.cardsList.find(card => card.cardId === model.card).cardNumberMasked,
                    key: 'card'
                });
            }
            if(model.status) {
                arr.push({
                    name: this.$store.state.asakaMobileTransactionStatuses[model.status],
                    key: 'status'
                })
            }
            if(model.transaction) {
                arr.push({
                    name: 'ID ' + model.transaction,
                    key: 'transaction'
                })
            }
            return arr;
        },
        stopScroll() {
            if(this.scrollTimeout) {
                clearInterval(this.scrollTimeout);
            }
        },
        scrollLeft() {
            this.scrollTimeout = setInterval(() => {
                this.$refs.scroller.scrollBy(-3,0)
            }, 5);
        },
        scrollRight() {
            this.scrollTimeout = setInterval(() => {
                this.$refs.scroller.scrollBy(3,0)
            }, 5);
        }
    }
}
</script>
<style lang="scss">
.nav-list {
    position: relative;
    &__scroller {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }
    &__item {
        cursor: pointer;
        user-select: none;
        flex-shrink: 0;
        white-space: nowrap;
        margin-right: 14px;
        padding: 10px 18px;
        border-radius: 30px;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: var(--color-primary);
        &--active {
            cursor: default;
            background-color: var(--color-primary);
            color: var(--color-white);
        }
    }
    &__target {
        position: absolute;
        width: 20%;
        top: 0;
        bottom: 0;
        &--left {
            left: 0;
            cursor: w-resize;
        }
        &--right {
            right: 0;
            cursor: e-resize;
        }
    }
}
.transactions-mobile-page {
    &__nav {
        margin-right: 32px;
    }
    &__table {
        overflow: hidden;
        border-radius: 12px;
    }
}
</style>
