<template>
    <div v-if="activeFilters.length" class="application-filter-pills">
        <div class="application-filter-pills__wrap">
            <div class="application-filter-pills__name">
                {{ $t("components.common.filter") }}
            </div>
            <div class="application-filter-pills__items">
                <div
                    v-for="item in activeFilters"
                    :key="item.key + item.name"
                    class="application-filter-pills__item filter-pill"
                    :class="{
                        ['filter-pill--theme-' + item.theme]: item.theme,
                    }"
                    @click="removeFilterItem(item)"
                >
                    <template v-if="getTranslationKeyForFilterLabel(item.key)">
                        {{ getTranslationOrKey(getTranslationKeyForFilterLabel(item.key)) }}:
                    </template>
                    {{ getTranslationOrKey(item.name) }}
                    <AppIcon class="filter-pill__close" icon="close" />
                </div>
            </div>
        </div>
        <div class="application-filter-pills__clear" @click="clearFilter">
            {{ $t("components.common.clearFilter") }}
        </div>
    </div>
</template>
<script>
import AppIcon from "../partials/AppIcon";
import { translationExists } from "../../mixins/local/translationExists.mixin";

export default {
    name: "ApplicationFilterPills",
    components: { AppIcon },
    mixins: [translationExists],
    props: {
        filter: {
            type: Object,
            required: true,
        },
        defaultFilter: {
            type: Object,
            required: true,
        },
        filterSources: {
            type: Object,
            required: true,
        },
        excludedFilters: {
            type: Array,
            required: true,
        },
        filterParser: {
            type: Function,
            default: () => () => ({}),
        },
        parseOptions: {
            type: Object,
            default: () => ({
                dateFrom: "created_at_from",
                dateTo: "created_at_to",
            }),
        },
    },
    data() {
        return {
            activeFilters: [],
        };
    },
    watch: {
        filter: {
            handler: "calculateActiveFilters",
            deep: true,
            immediate: true,
        },
        filterSources: {
            handler: "calculateActiveFilters",
            deep: true,
            immediate: true,
        },
    },
    methods: {
        calculateActiveFilters() {
            const excludedKeys = this.excludedFilters;

            let filters = Object.entries(this.filter)
                .filter(([key, value]) => {
                    return !(
                        excludedKeys.includes(key) ||
                        (Array.isArray(value) && !value.length) ||
                        value == this.defaultFilter[key]
                    );
                })
                .map(this.getFilterItem)
                .reduce((acc, curr) => {
                    if (Array.isArray(curr)) {
                        return [...acc, ...curr];
                    } else {
                        return [...acc, curr];
                    }
                }, []);

            if (
                this.filter[this.parseOptions.dateFrom] &&
                this.filter[this.parseOptions.dateTo]
            ) {
                const fromDate =
                    this.filter[this.parseOptions.dateFrom].split("-");
                const toDate = this.filter[this.parseOptions.dateTo].split("-");
                filters.push({
                    name: `${fromDate[2]}.${fromDate[1]}.${fromDate[0].slice(
                        2
                    )} - ${toDate[2]}.${toDate[1]}.${toDate[0].slice(2)}`,
                    key: "date",
                });
            } else if (
                this.filter[this.parseOptions.dateFrom] ||
                this.filter[this.parseOptions.dateTo]
            ) {
                const date = (
                    this.filter[this.parseOptions.dateFrom] ||
                    this.filter[this.parseOptions.dateTo]
                ).split("-");
                filters.push({
                    name: `${date[2]}.${date[1]}.${date[0].slice(2)}`,
                    key: "date",
                });
            }

            const processed = this.filterParser(filters, this.filter);
            for (let i of processed) {
                const f = filters.find((filter) => filter.key === i.key);
                if (f) {
                    f.name = i.name;
                } else {
                    filters.push(i);
                }
            }

            this.$emit("pills-calc", filters.length);
            this.activeFilters = filters;
        },
        getFilterItem([key, value]) {
            const sources = this.filterSources;
            if (sources[key] && sources[key].length) {
                if (Array.isArray(value)) {
                    return sources[key]
                        .filter((item) => value.includes(item.id))
                        .map((option) => ({
                            id: option.id,
                            name: option.name || option.title,
                            key,
                        }));
                } else {
                    const el = sources[key].find((item) => item.id === value);
                    if (el) {
                        return {
                            name: el.name || el.title,
                            key: key,
                        };
                    } else {
                        return {
                            name: value,
                            key,
                        };
                    }
                }
            } else {
                return {
                    name: value,
                    key,
                };
            }
        },
        removeFilterItem(item) {
            if (item.key === "date") {
                this.$emit(
                    "input",
                    this.parseOptions.dateFrom,
                    this.defaultFilter[this.parseOptions.dateFrom]
                );
                this.$emit(
                    "input",
                    this.parseOptions.dateTo,
                    this.defaultFilter[this.parseOptions.dateTo]
                );
            }
            if (Array.isArray(this.filter[item.key])) {
                const ids = [...this.filter[item.key]];
                ids.splice(
                    ids.findIndex((id) => id === item.id),
                    1
                );
                this.$emit("input", item.key, ids);
            } else {
                this.$emit("input", item.key, this.defaultFilter[item.key]);
            }
        },
        clearFilter() {
            this.$emit("clear");
        },
        getTranslationKeyForFilterLabel(key) {
            const trs = {
                call_type: "common.line",
                client_type: "common.table.clientCategory",
                has_redmine: "common.appealFromRedmine",
                is_company: "common.legalStatus",
                language_id: "common.table.languageOfAddress",
                marketing_id: "common.table.howDidTheClientFindOut",
                operator_ids: "common.table.operator",
                product_ids: "common.table.theme",
                source_id: "common.table.sourceOfReference",
                status: "common.status",
                type_id: "common.type",
                username: "common.table.fio",
                phone: "common.table.phoneNumber",
                grade: "common.table.averageRating",
                date: "common.table.date"
            };

            const result = trs[key];

            if (result) {
                return result;
            }

            return null;
        },
    },
};
</script>
<style lang="scss">
@import "../../assets/scss/variables";
@import "../../assets/scss/components/filter-pill";

.application-filter-pills {
    padding: 19px 0 20px;
    border-top: 1px solid #e1e1e1;
    display: flex;
    align-items: flex-start;
    &__wrap {
        flex-grow: 1;
    }
    &__name {
        padding: 8px 0;
        float: left;
        display: inline-block;
        font-family: $font-secondary;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.005em;
    }
    &__items {
        margin-left: -12px;
        margin-top: -12px;
    }
    &__item {
        margin-left: 12px;
        margin-top: 12px;
    }
    &__clear {
        flex-shrink: 0;
        margin-left: auto;
        cursor: pointer;
        user-select: none;
        padding: 8px 0;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.005em;
        text-decoration: underline;
        color: var(--color-primary);
    }
}
.filter-pills {
    &-enter-active,
    &-leave-active {
        transition: height 0.25s;
        overflow: hidden;
    }
}
</style>
